.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  text-align: center;
  color: var(--color-text-2);
  
  svg{
    margin-right: 8px;
  }
}
